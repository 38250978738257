import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useSelector } from 'react-redux'
import { roundAndFix, removeVatFrom } from '../scripts/helpers'
import { PageTemplate } from './page'
import Img from 'gatsby-image'
import SiteMetadata from '../components/SiteMetadata'
import { add } from 'cart-localstorage'
import SpecialNote from '../components/SpecialNote'
import FaqSection from '../components/shortcodes/FaqSection'
import Cta from '../components/shortcodes/Cta'
import Plural from '../components/shortcodes/Plural'
// import PageContent from '../components/shortcodes/PageContent'
import ProductPageStyles from '../styles/ProductPage.module.sass'

/**
 * Create Product Template Component
 */
const Product = ({ data }) => {
	const { maxProductQuantity } = SiteMetadata()

	const selectedCurrency = useSelector((state) => state.currency)

	const Settings = require('../data/site-settings.json')

	let priceSource = data.mdx.frontmatter.price

	if (selectedCurrency === 'GBP') {
		priceSource = data.mdx.fields.priceGbp
	}
	else if (selectedCurrency === 'USD') {
		priceSource = data.mdx.fields.priceUsd
	}
	else if (selectedCurrency === 'AUD') {
		priceSource = data.mdx.fields.priceAud
	}

	const price = roundAndFix(priceSource)
	const adjustedPrice = price.toString().split('.')

	// const priceWithoutVat = removeVatFrom(price)

	// const amountVat = roundAndFix(price - priceWithoutVat)

	function addToOrderAction(id, title, price, quantity, intlAvailable) {

		add({ id: id, name: title, price: price, intlAvailable }, quantity)

		// Reload the page to update the floating cart
		// TODO: Research using Redux for this purpose in the future
		window.location.href = '/checkout'
	}

	const [qty, setQty] = useState(1)

	// Increase/Decrease quantity using buttons
	function changeQty(operation) {
		// Prevent decreasing to 0 or increasing above maxProductQuantity
		if (
			(qty <= 1 && operation === 'decrease') ||
			(qty >= maxProductQuantity && operation === 'increase')
		) {
			return false
		}

		// Clone and change quantities
		if (operation === 'decrease') {
			setQty(~~qty - 1)
		} else {
			setQty(~~qty + 1)
		}
	}

	// Increase/Decrease quantity using number input
	function inputQty(input) {
		if (input === '' || (~~input > 0 && ~~input <= maxProductQuantity)) {
			setQty( ~~input )
		}
		else if (input < 1) {
			setQty(1)
		}
		else if (input > maxProductQuantity) {
			setQty(maxProductQuantity)
		}
	}

	return (
		<PageTemplate
			pageClass={`${data.mdx.frontmatter.pageClass || ''} ${ProductPageStyles.page || ''} hide-cart-if-empty wave-on-top footer-dimmed`}
			pageIdentifier={data.mdx.fields.pageIdentifier}
			showCta={false}
			showContactForm={false}
			title={data.mdx.frontmatter.title}
			notNarrow={true}
		>
			{/* Columns */}
			<div className={`columns is-desktop ${ProductPageStyles.columnsContainer || ''}`}>

				{/* Product Image */}
				<div className={`column ${ProductPageStyles.imageHolder || ''}`}>
					{data.mdx.fields.image ? (
						data.mdx.fields.image.childImageSharp ? (
							<div className={ProductPageStyles.image || ''}>
								<Img
									fluid={data.mdx.fields.image.childImageSharp.fluid}
									alt={data.mdx.frontmatter.title || ''}
									title={data.mdx.frontmatter.title || ''}
								/>
							</div>
						) : (
							<div className={ProductPageStyles.image || ''}>
								<img
									src={data.mdx.fields.image}
									alt={data.mdx.frontmatter.title || ''}
									title={data.mdx.frontmatter.title || ''}
								/>
							</div>
						)
					) : null}
				</div>

				{/* Vitals */}
				<div className={`column ${ProductPageStyles.vitalsHolder}`}>
					{/* Full Description */}
					{data.mdx.frontmatter.internationalDeliveryAvailable && <p><strong>International Delivery Available</strong></p>}
					<p 
						className={ProductPageStyles.shortDescription || ''}
						dangerouslySetInnerHTML={{ __html: (data.mdx.frontmatter.fullDescription || data.mdx.frontmatter.shortDescription) }}
					/>

					{/* Hamper/Box Contents */}
					<strong className={`mini-title ${ProductPageStyles.miniTitle || ''}`}>
						This {data.mdx.frontmatter.productType} Contains:
					</strong>

					<ul className='fancy-list'>
						{data.mdx.frontmatter.contents.map((item, i) => {
							return <li key={item + i}>{item}</li>
						})}
					</ul>

					{/* Add to Order */}
					<div className={ProductPageStyles.addToOrder || ''}>
						{/* Price Breakdown */}
						<div className={ProductPageStyles.prices || ''}>
							<ul className={ProductPageStyles.priceList || ''}>
								<li className={ProductPageStyles.total || ''}>
									<i dangerouslySetInnerHTML={{ __html: Settings['availableCurrencies'][selectedCurrency]['symbol'] }} /><b><span>{adjustedPrice[0]}</span><small>{adjustedPrice[1]}</small></b>
								</li>
								{/* <li>Price without VAT: &euro;{priceWithoutVat}</li> */}
								{/* <li>VAT: &euro;{amountVat}</li> */}
							</ul>
						</div>
						
						{/* Quantity Selector & Add to Order Button */}
						<div className={ProductPageStyles.actions || ''}>
							<div className={ProductPageStyles.quantityWidget || ''}>
								<input
									type="text"
									name="quantity"
									value={qty}
									onChange={e => {
										inputQty(e.target.value)
									}}
								/>
								<span
									className={`tag ${ProductPageStyles.increase || ''}`}
									onClick={() => {
										changeQty('increase')
									}}
									onKeyDown={() => {
										changeQty('increase')
									}}
									role="button"
									tabIndex={0}
								>
									+
								</span>
								<span
									className={`tag ${ProductPageStyles.decrease || ''}`}
									onClick={() => {
										changeQty('decrease')
									}}
									onKeyDown={() => {
										changeQty('decrease')
									}}
									role="button"
									tabIndex={0}
								>
									–
								</span>
							</div>

							<button
								className={`button is-secondary is-strong ${ProductPageStyles.addToOrderButton || ''}`}
								disabled={data.mdx.frontmatter.stockStatus === 'outOfStock' ? true : false}
								onClick={() => {
									addToOrderAction(
										data.mdx.frontmatter.productID,
										data.mdx.frontmatter.title,
										data.mdx.frontmatter.price,
										qty,
										data.mdx.frontmatter.internationalDeliveryAvailable ? data.mdx.frontmatter.internationalDeliveryAvailable : false
									)
								}}
							>
								{data.mdx.frontmatter.stockStatus === 'outOfStock' ? 'Out of Stock' : 'Add to Order'}
							</button>
						</div>
					</div>

					{/* Special Note */}
					<SpecialNote />
				</div>
			</div>

			<div className={`container is-narrow ${ProductPageStyles.belowProduct || ''}`}>
				{/* FAQs */}
				<h2 className="title is-2">About Our <Plural for={data.mdx.frontmatter.productType} fallback='product' capitalize='true' /></h2>
				<FaqSection />

				{/* Scroll Back to Product */}
				<Cta before="Sounds good? View and order this product." text="Scroll to Product" link="#" buttonClassName="is-secondary" />
			</div>

			{/* Schema */}
			{data.mdx.frontmatter.schema ?
				<script type="application/ld+json">
					{data.mdx.frontmatter.schema}
				</script>
				: null
			}
		</PageTemplate>
	)
}

export default Product

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      fields {
        pageIdentifier
        slug
				image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
				priceGbp
				priceUsd
				priceAud
      }
      frontmatter {
				contents
				price
				productType
				productID
        schema
				shortDescription
				fullDescription
				internationalDeliveryAvailable
				stockStatus
				title
      }
    }
  }
`